import { mapActions } from 'vuex'

export default{
    
  data: () => ({
    collections: [],
    params: {},
    message: '',
    onlyActive: true,
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'Nome', value: 'name'},
        { text: 'Periodo', value: ['start', 'end'], type: 'date', range: true, align: 'center'},
        { text: 'Disponibilidade', value: ['availability_start', 'availability_end'], range: true, type: 'date', align: 'center'},
        { text: 'Atribuição', value: ['indication_start', 'indication_end'], range: true, type: 'date', align: 'center'},
        { text: 'Fechado', value: 'closed', type: 'boolean', align: 'center'},
        { text: 'Ativo', value: 'active' },
        { text: 'Ações', value: 'actions', sortable: false}
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir " + item_new.name + "?";
              //this.itemDelete = item_new;
          } else {
              this.show = false
              this.textDelete = '';
              //this.itemDelete = {};
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },

      onlyActive(val){
        this.getData();
      },
    },
    methods: {
      ...mapActions('worktime', ['ActionGetWorkTimes', 'ActionDeleteWorkTime']),
        
      getData(){
      
      this.loadingProgress = true;
      this.showForm = false;
      this.params.with = 'frequency,dates,category';

      if(this.onlyActive){
        this.params.find_columns = {
            active: 1,
        };
      }else{
        delete this.params.find_columns;
      }

      this.params.column_order = 'name';
      this.params.direction = 'desc';
      
      this.ActionGetWorkTimes(this.params)
              .then((res) => {
                  this.collections = res.data;
                  this.meta = res.meta;
                  this.loadingProgress = false;
      });
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteWorkTime({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }

  },

}
